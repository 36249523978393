import {
  ArrowDownHeaderSVG,
  CartHeaderSVG,
  CartIcon,
  Logo,
  MenuMobileIconIcon,
  PhoneHeaderSVG,
  PhoneIcon,
  SearchHeaderSVG,
  UserHeaderSVG,
  UserIcon,
} from "@assets/icons";
import HeaderSearch from "@components/common/HeaderSearch";
import { Link, useNavigate } from "react-router-dom";
import { ROLES, ROUTES } from "@utility/constants";
import CategoryItem from "../CategoryItem";
import CartProductNew from "@components/Cart/CartProductNew";
import CartProductHover from "@components/Cart/CartProductHover";
import useI18n from "@hooks/useI18n";
import { useContext, useEffect, useState } from "react";
import { ModalContext } from "@contexts/contextModal";
import HeaderMenuMobile from "../HeaderMenuMobile";
import { useCart } from "@contexts/CartContext";
import FooterServices, { ContentFooter, ResponseFooter } from "@services/FooterService";
import CategoryProductServices, {
  ProductCategoryHeader,
  ProductCategoryHeaderItem,
  ProductTrademarkHeader,
} from "@services/CategoryProductServices";
import { firstUpper, some } from "@utility/helper";
import AuthService from "@services/AuthServices";
import clsx from "clsx";
import useScrollDirection from "@hooks/useScrollDirection";
import PolicyServices from "@services/PolicyServices";
import HeaderSearchExpand from "./search/HeaderSearchExpand";
import { useClickOutItem } from "@hooks/useClickOutItem";


const mapped = [
  {
    label: "Về Supershop",
    href: "/about-us",
  },
];

export type CategoryHeaderItem = {
  id?: number | null
  title: string;
  items?: CategoryHeaderItem[];
};

export type CategoryHeader = {
  text: string;
  items: CategoryHeaderItem[];
};

const HeaderNew = () => {
  const { t } = useI18n();
  const navigate = useNavigate();
  const { setContentModal, setShowModal } = useContext(ModalContext);
  const cartContext = useCart();
  const [footerInfo, setFooterInfo] = useState<ContentFooter>();
  const [maleCategory, setMaleCategory] = useState<ProductCategoryHeader[]>();
  const [femaleCategory, setFemaleCategory] =
    useState<ProductCategoryHeader[]>();
  const [trademarkCategory, setTrademarkCategory] =
    useState<ProductTrademarkHeader[]>();
  const [trademarkCategoryDiffer, setTrademarkCategoryDiffer] =
    useState<ProductTrademarkHeader[]>();
  const [categoryHeader, setCategoryHeader] = useState<CategoryHeader[]>([]);

  const { ref, isShow, handleToggleItem } = useClickOutItem();

  useEffect(() => {
    try {
      getHeaderMale();
    } catch (error) {}
  }, []);

  const openMenuMobile = () => {
    setContentModal(<HeaderMenuMobile categoryData={categoryHeader} />);
    setShowModal(true);
  };

  useEffect(() => {
    try {
      FooterServices.get().then((data) => {
        
        setFooterInfo(data);
      });
    } catch (error) {}
  }, []);

  const userLogin = () => {
    if(!AuthService.isLoggedIn()) {
      AuthService.doLogin();
    }
  };
  const userLogout = () => {
    AuthService.doLogout();
  };
  const getHeaderMale = () => {
    CategoryProductServices.getFilterCategory("male").then((data) => {
      setMaleCategory(data.data);
      let m: CategoryHeader = {
        text: "Nam",
        items: data.data.map((it, i) => {
          return {
            id: it.id,
            title: it.categoryName,
            items: it.categoryNewDetails?.map(c => {
              return {
                id: c.id,
                title: c.categoryDetailName
              }
            })
          };
        }),
      };
      setCategoryHeader((pre) => {
        pre.push(m);
        getHeaderFemale();
        return [...pre];
      });
    });
  };

  const getHeaderFemale = () => {
    CategoryProductServices.getFilterCategory("female").then((data) => {
      setFemaleCategory(data.data);
      let m: CategoryHeader = {
        text: "Nữ",
        items: data.data.map((it, i) => {
          return {
            id: it.id,
            title: it.categoryName,
            items: it.categoryNewDetails?.map(c => {
              return {
                id: c.id,
                title: c.categoryDetailName
              }
            })
          };
        }),
      };
      setCategoryHeader((pre) => {
        pre.push(m);
        getHeaderTrademark();
        return [...pre];
      });
    });
  };

  const getHeaderTrademark = () => {
    CategoryProductServices.getTrademarkHeader().then((data) => {
      // console.log(data);

      setTrademarkCategory(data);
      let m: CategoryHeader[] = data.map((tmh, i) => {
        let items = [];
        if (tmh.male) {
          items.push({
            title: "Nam",
            items: tmh.male.map(c => {
              return {
                id: c.id,
                title: c.categoryName
              }
            })
          });
        }

        if (tmh.female) {
          items.push({
            title: "Nữ",
            items: tmh.female.map(c => {
              return {
                id: c.id,
                title: c.categoryName
              }
            })
          });
        }

        return {
          text: tmh.tradeMarkName,
          items: items,
        };
      });

      setCategoryHeader((pre) => {
        getHeaderTrademarkDiffer();
        return [...pre, ...m];
      });
    });
  };

  const getHeaderTrademarkDiffer = () => {
    CategoryProductServices.getTrademarkDiffer().then((data) => {
      // console.log(data.data);

      setTrademarkCategoryDiffer(data);
      if(data && data.length > 0) {
        let m: CategoryHeader = {
          text: "Thương hiệu khác",
          items: data.map((tmh: ProductTrademarkHeader) => {
            return {
              title: tmh.tradeMarkName,
              items: []
            };
          }),
        };
  
        setCategoryHeader((pre) => {
          return [...pre, m];
        });
      }
    });
  };

  // console.log(categoryHeader);
  

  const routeAboutus = [
    {
      name: "Về Supershop",
      link: "/about-us"
    },
    {
      name: "Khuyến mại",
      link: "/about-us/news"
    },
    {
      name: "Liên hệ",
      link: "/about-us/contact"
    },
  ]

  const scrollDirection = useScrollDirection();

  const [policy, setPolicy] = useState([])
  useEffect(() => {
    try {
      fetchData()
        .then(data => {
          setPolicy(data.data.data)
        })
    } catch (error) {
      
    }
  }, [])

  const fetchData = async () => {
    return  await PolicyServices.get({page: 1, limit: 10})
  }


  // const [showSearch, setshowSearch] = useState(false)
  

  return (
    <div className={`sticky ${ scrollDirection === "down" ? "lg:!-top-[56px] shadow-sm" : "top-0"} top-0 z-50 transition-all duration-500`}>
      <div className="hidden lg:block bg-[#131315] h-[56px] "  >
        <div className="flex container px-4 lg:px-16 xl:px-24  justify-between items-center h-full">
          <a
            href={`tel:${footerInfo?.phoneNumber[0].replace(/\s/g, "")}`}
            className="hover:cursor-pointer flex items-center justify-center"
          >
            <PhoneHeaderSVG />
            <p className="px-2 text-wap-regular2 font-semibold text-white  ">
              {t("header.hotline")}:{" "}
              {footerInfo?.phoneNumber[0] || "1800.3675"}
            </p>
          </a>
          <div className="flex">
              {
                AuthService.hasRole([ROLES.admin, ROLES.system]) ? <Link to={"/admin"}  
                className={"text-white font-medium text-wap-regular2 px-4 border-r-[1px] border-r-white"}
                >Quản lý</Link> : <></>
              }
              
            {
              routeAboutus.map((item: any, i: number) => {
                return <Link to={item.link}  key={i}
                className={"text-white font-medium text-wap-regular2 px-4 border-r-[1px] border-r-white"}
              >{item.name}</Link>
              })
            }
            <div className="group flex items-center justify-center relative">
              <Link to={"/"} 
                className={"text-white font-medium text-wap-regular2 pl-4 pr-2"}
              >{"Hỗ trợ khách hàng"}</Link>
              <ArrowDownHeaderSVG />

              <div className="rounded-sm lg:group-hover:block hidden absolute top-[calc(100%_+_12px)]  -right-0 z-10 bg-white py-3">
                <div className="absolute w-full h-3 bottom-full left-0 "></div>
                {
                  policy.map((item:any, i:any) => {
                    return <Link to={`/about-us/policy/${item.id}`} 
                    
                      className={"text-[#131315] font-medium text-wap-regular2 py-3 px-3 block whitespace-nowrap hover:bg-slate-200 hover:text-main"} key={i}
                    >{firstUpper(item.title)}</Link>
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-[68px] lg:h-[88px] bg-white">
        <div className="container px-4 lg:px-16 xl:px-24 flex justify-between items-center h-full">
          <Link to={ROUTES["homepage"]}>
            <Logo className="" fill="white" width={142} />
          </Link>
          <div className="hidden lg:block h-full ml-2">
            <div className="flex gap-4 xl:gap-9 h-full">
                {categoryHeader.map((it, idx) => (
                  <CategoryItem key={idx} data={it} />
                ))}
            </div>
          </div>
          <div className="flex">
            <div className="ml-6 cursor-pointer h-full"
              onClick={(e) => {handleToggleItem()}}
            >
              <SearchHeaderSVG />
            </div>
            <div
                className="ml-6 relative group hover: cursor-pointer"
              onClick={() => navigate(ROUTES["cart"]["index"])}
            >
              <CartHeaderSVG className="" />
              {cartContext.cartQuantity > 0 && (
                <>
                  <div className="absolute -top-1.5 -right-2.5 rounded-full bg-cancel w-auto min-w-[20px] h-5 flex justify-center items-center ">
                    <p className="text-white text-wap-regular1 text-center">
                      {cartContext.cartQuantity}
                    </p>
                  </div>
                  <div className="lg:group-hover:block hidden absolute top-[calc(100%_+_28px)]  -right-9 z-10">
                    <CartProductHover />
                  </div>
                </>
              )}
            </div>
            <div className="hidden lg:block relative group ml-6">
              <UserHeaderSVG
                className={clsx(" fill-gray-300 ", {
                  "cursor-pointer": !AuthService.isLoggedIn()
                })}
                onClick={userLogin}
              />
              {
                AuthService.isLoggedIn() && 
                <div className="lg:group-hover:block hidden absolute top-[calc(100%_+_16px)]  -right-3 z-10">
                  <div className=' bg-white shadow-md rounded-md w-fit h-fit relative '>
                    <div className="trangle arrow-up absolute bottom-full right-4 border-l-[8px] border-r-[8px] border-b-[8px] border-b-white group-hover:border-b-icon"></div>
                    <div className="absolute bottom-full w-full h-4 left-0"></div>
                    <p className="rounded-md px-4 py-2 whitespace-nowrap cursor-pointer bg-icon hover:text-main transition-all"
                      onClick={userLogout}
                    > {t("header.logout")}</p>
                  </div>
                </div>
              }
            </div>
            <MenuMobileIconIcon
                onClick={openMenuMobile}
                className="block lg:hidden fill-[#131315] w-6 ml-6 hover:cursor-pointer"
              />
          </div>
        </div>
      </div>
      <div className={`absolute -z-2 bg-[#131315] w-full left-0 flex justify-center items-center ${ isShow && ref  ? "top-full" : "top-[calc(100%-48px)]"} transition-all  duration-500`}
        ref={ref}
      >
        <HeaderSearchExpand />
      </div>
    </div>
  );
};

export default HeaderNew;
