import { UtilHomepage1SVG, UtilHomepage2SVG, UtilHomepage3SVG, UtilHomepage4SVG } from '@assets/icons'
import HomeServices from '@services/HomeServices'
import { some } from '@utility/helper'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

const utilShopsFix: some[] = [
  {
    id: 1,
    // title: "GIAO HÀNG MIỄN PHÍ",
    // content: "Áp dụng cho đơn hàng > 1,000,000VND",
    icon: <UtilHomepage1SVG className='w-full h-auto fill-main' />
  }, 
  {
    id: 2,
    // title: "DỄ DÀNG ĐỔI TRẢ",
    // content: "Đổi hàng nhanh chóng trong vòng 30 ngày",
    icon: <UtilHomepage2SVG className='w-full h-auto fill-main' />
  }, 
  {
    id: 3,
    // title: "CAM KẾT CHÍNH HÃNG",
    // content: "Sản phẩm được bán trực tiếp từ hàng chính hãng",
    icon: <UtilHomepage3SVG className='w-full h-auto fill-main' />
  }, 
  {
    id: 4,
    // title: "CSKH BẢO HÀNH",
    // content: "Hotline: 0905 38 69 68 (8:00 - 17:00)",
    icon: <UtilHomepage4SVG className='w-full h-auto fill-main' />
  }, 
]

function UtilHompage() {

  const [utilShops, setUtilShops] = useState(utilShopsFix)

  useEffect(() => {
    try {

      HomeServices.getUtil()
        .then(data => {
          setUtilShops(pre => {
            let utilData = data.data.data;
            if(utilData) {
              for(let i = 0; i < pre.length; i++) {
                pre[i].id = utilData[i]?.id
                pre[i].title = utilData[i]?.title
                pre[i].content = utilData[i]?.content
              }
            }
            return [...pre]
          })
        })
      } catch (error) {
      
      }
    
  }, [])  

  return (
    <div className="grid xl:grid-cols-4 grid-cols-2 h-auto xl:h-[194px] bg-[#F4F4F4]">
      {
        utilShops.map((u, i) => {
          return <div className={clsx("flex-1 px-4 sm:px-6 flex flex-col items-center justify-center gap-1 sm:gap-3 pt-4 pb-4   ", {})} key={i}>
            <div className="flex lg:flex-col items-center justify-center gap-2 sm:gap-4">
              <div className="w-1/4 lg:w-auto h-[80px] flex justify-center items-center">{u.icon}</div>
              <h3 className='flex-1 text-center text-normal lg:text-[20px] font-bold '>{u.title}</h3>
            </div>
            <h4 className='text-center text-wap-regular2 lg:text-normal mt-1 md:mt-0'>{u.content}</h4>
          </div>
        })
      }
    </div>
  )
}

export default UtilHompage