import React, { useEffect, useState } from 'react'
import TitleHomepage from '../title/TitleHomepage'
import SubtitleHomepage from '../title/SubtitleHomepage'
import SaleProductHomepage from './SaleProductHomepage'
import ProductServices, { ProductItem } from '@services/ProductServices'
import CategoryProductServices from '@services/CategoryProductServices'

function HomepageSale() {
  const [hotSold, setHotSold] = useState<ProductItem[]>([])
  useEffect(() => {
    try {
      let request = {
        saleMin: 1,
        saleMax: 100
      }
  
      CategoryProductServices.search(request, 0, 20, "saleMax,desc", new AbortController())
        .then(data => {
          setHotSold(data)
          return data
        })

    } catch (error) {
      
    }
      
  }, [])

  if(!hotSold || hotSold.length == 0) return <></>

  return (
    <div className='container px-4 lg:px-16 xl:px-24 mt-8 md:mt-16 '>
      <TitleHomepage text="sản phẩm giảm giá"/>
      <SubtitleHomepage text="sản phẩm giảm giá"/>
      <div className="py-5 md:py-8 lg:py-12">
          <SaleProductHomepage hotSold={hotSold} />
      </div>
    </div>
  )
}

export default HomepageSale