// import MainSidebar from "./components/MainSidebar";

import FabComponent from "@components/fab";
import { useLocation } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import HeaderAdmin from "./components/HeaderAdmin";
import FooterNew from "./components/FooterNew";
import HeaderNew from "./components/header/HeaderNew";

const DefaultLayout = ({ children }: { children: JSX.Element }) => {
  const router = useLocation().pathname.includes("admin");

  return (
    <div className="min-h-screen bg-white">
      {!router ? <HeaderNew /> : <HeaderAdmin />}

      <div className="min-h-[800px]">
        {/* new changes 22/4 2022*/}
        {/* <Outlet /> */}
        {children}
      </div>
      <FooterNew />
      {!router ? <FabComponent /> : <></>}
      
    </div>
  );
};

export default DefaultLayout;
