import { ClearIcon, ShopIcon, UserIcon } from '@assets/icons'
import { ModalContext } from '@contexts/contextModal'
import useI18n from '@hooks/useI18n'
import { some } from '@utility/helper'
import React, { useContext } from 'react'
import CategoryMobileItem from './CategoryMobileItem'
import { Link } from 'react-router-dom'
import AuthService from '@services/AuthServices'
import { CategoryHeader } from './Header'


interface Props {
  categoryData: CategoryHeader[];
}

function HeaderMenuMobile({categoryData}: Props) {

  const {t} = useI18n()
  const {closeModal} = useContext(ModalContext)

  const userLogin = () => {
    AuthService.doLogin();
  };
  const userLogout = () => {
    AuthService.doLogout();
  };

  return (
    <div className='h-screen w-screen'>
        <div className="w-3/4 h-full bg-white ml-auto  flex flex-col justify-between">
          <div className="px-5 hover:cursor-pointer py-6 flex justify-end" onClick={closeModal}>
              <ClearIcon  />
          </div>
          <div className="px-5 flex-1 overflow-y-auto overflow-x-hidden pb-8">
            {
              categoryData.map((it: CategoryHeader, idx: any) => (
                <CategoryMobileItem key={idx} data={it} />
              ))
            }
          </div>

          <div className="px-5 py-6 border-t border-t-gray-200 ">
            <Link to={'/about-us'} className="flex  items-center " onClick={closeModal}>
              <div className="w-7 mr-4">
                <ShopIcon className="w-full h-auto fill-gray-300" />
              </div> 
              <span className=" text-normal1">{t("header.about")}</span>
            </Link>
            <div className=" mt-4 flex  items-center ">
              <div className="w-7 mr-4">
                <UserIcon className="w-full  h-auto fill-gray-300" />
              </div>
              {
                  !AuthService.isLoggedIn() ? <span className=" text-normal1"  onClick={userLogin}>{t("header.login")}</span>
                    : <span className=" text-normal1"  onClick={userLogout}>{t("header.logout")}</span>
              }
            </div>
          </div>
        </div>
    </div>
  )
}

export default HeaderMenuMobile