import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import './banner.css'

const banner = "/banner.png";
const Banner = ({className, images, ...props}: any) => {

  const pagination = {
    clickable: true,
    renderBullet: function (index: any, className: any) {
      return '<span class="' + className + '"></span>';
    },
  };

  return (
    <div className={`w-full  rounded-md ${className}`} {...props}>
      <Swiper 
        className="w-full h-full rounded-md"
        modules={[Autoplay, Pagination]}
        spaceBetween={24}
        slidesPerView={1}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        loop={true}
        pagination={pagination}
      >
      {
        images?.map((src:string, i:any) => {
          return <SwiperSlide key={i}>
              <img src={src} className="w-full h-full object-cover rounded-md" alt="image banner homepage" 
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src="/images-raw/noimage.jpg";
                }}
              />
            </SwiperSlide>
        })
      }
      </Swiper>
    </div>
    
  );
};

export default Banner;
