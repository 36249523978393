import {
  FacebookFilledIcon,
  InstagramOutlineIcon,
  Logo,
  MailOutlineIcon,
  PhoneOutlineIcon,
  TikTokIcon,
  WebsiteOutlineIcon,
  WhiteLogo,
  YoutubeOutlineIcon,
} from "@assets/icons";
import ContactFooterForm from "@components/Form/ContactFooterForm";
import useI18n from "@hooks/useI18n";
import FooterServices, { ContentFooter, ResponseFooter } from "@services/FooterService";
import PolicyServices from "@services/PolicyServices";
import { firstUpper } from "@utility/helper";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface Props {}

const FooterNew = (props: Props) => {

  const {t} = useI18n()
  const [policy, setPolicy] = useState([])
  const [footerInfo, setFooterInfo] = useState<ContentFooter>()

  const routeAboutus = [
    {
      name: t("about_us.introduce_title"),
      link: "/about-us"
    },
    {
      name: t("about_us.news_title"),
      link: "/about-us/news"
    },
    {
      name: t("about_us.contact_title"),
      link: "/about-us/contact"
    },
  ]

  useEffect(() => {
    try {
      fetchData()
        .then(data => {
          setPolicy(data.data.data)
          
        })

      FooterServices.get()
        .then(data => {
          setFooterInfo(data)
          
        })
    } catch (error) {
      
    }
  }, [])
  

  const fetchData = async () => {
    return  await PolicyServices.get({page: 1, limit: 10})
  }

  return (
    <div className="bg-[#131315]">
      <div className="container px-4 lg:px-8 pt-10 pb-4">
        <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-2 xl:gap-10">
          <div>
            <Logo  className=""/>
            <div className="pt-4 lg:pt-6 text-wap-regular2 text-white">
              <p className="">
                {footerInfo?.comanyName}
              </p>
              <div className="pt-4">
                {
                  footerInfo?.address.map((a, i) => {
                    return <p className="mt-1" key={i}>{a}</p>
                  })
                }
              </div>
              <p className="pt-5 flex items-center">
                <span className="mr-4">{t("footer.phone")} </span> 
                {footerInfo?.phoneNumber.map((p, i) => {
                  if(i < footerInfo?.phoneNumber.length - 1)
                    p += " / "
                  return <a key={i} href={`tel:${p.replace(/\s/g, '').replace('/', '')}`} className="">{p}</a>
                })}
              </p>
              <div className="pt-6 flex items-center gap-5">
                {
                  footerInfo?.email && footerInfo?.email.length > 0 && <a href={`mailto:${footerInfo?.email}`}>
                    <MailOutlineIcon />
                  </a>
                }

                {
                  footerInfo?.facebook && footerInfo?.facebook.length > 0 && <a target="_blank" href={`${footerInfo?.facebook}`}>
                    <FacebookFilledIcon />
                  </a>
                }

                {
                  footerInfo?.youtube && footerInfo?.youtube.length > 0 && <a target="_blank" href={`${footerInfo?.youtube}`}>
                    <YoutubeOutlineIcon />
                  </a>
                }

                {
                  footerInfo?.instagram && footerInfo?.instagram.length > 0 && <a target="_blank" href={`${footerInfo?.instagram}`}>
                    <InstagramOutlineIcon />
                  </a>
                }

                {
                  footerInfo?.tiktok && footerInfo?.tiktok.length > 0 && <a target="_blank" href={`${footerInfo?.tiktok}`}>
                    <TikTokIcon />
                  </a>
                }
                
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div className="pt-6 lg:pt-0">
              <h1 className="text-[20px] leading-[26px] font-semibold text-white">
              {t("footer.about")}
              </h1>
              <div className="pt-6">
                {routeAboutus.map((it, idx) => (
                  <Link to={it.link} key={idx}>
                    <p className="pb-5 text-wap-regular2 text-white ">
                      {it.name}
                    </p>
                  </Link>
                ))}
              </div>
            </div>
            <div className="">
              <h1 className="text-[20px] leading-[26px] font-semibold text-white">
                {t("footer.policy")}
              </h1>
              <div className="pt-6">
                {policy.map((it:any, idx:any) => (
                  <Link to={`/about-us/policy/${it.id}`}  key={idx}>
                    <p className="pb-5 text-wap-regular2 text-white ">
                      {firstUpper(it.title)}
                    </p>
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="">
            <ContactFooterForm />
          </div>
        </div>
        <div>
          <p className="text-center text-white text-[12px] mt-6 lg:mt-4">{t("footer.copyright")}</p>
        </div>
      </div>
    </div>
  );
};

export default FooterNew;
