import { ProductItem } from '@services/ProductServices'
import React from 'react'
import Banner from './Banner/Banner'
import ProductCardCollectionHomepage from './ProductCardCollectionHomepage'

type Props = {
  data: ProductItem[]
  banner: string[]
}

function CollectionProductHomepage({data, banner}: Props) {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-6">
      <div className="lg:row-span-2 col-span-2 lg:col-span-1 !w-full !h-full">
        <Banner className={` !w-full !h-full`} images={banner}  />
      </div>
      {
        data.map((p, i) => {
          return <ProductCardCollectionHomepage product={p} key={p.id} />
        })
        
      }
    </div>
  )
}

export default CollectionProductHomepage