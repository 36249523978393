import React from 'react'
import BannerNew from './components/banner/BannerNew'
import UtilHompage from './components/UtilHompage'
import HomepageSale from './components/sale/HomepageSale'
import ProductNewHomepage from './components/product-new/ProductNewHomepage'
import ProductHotSoldHomepage from './components/hot-sold/ProductHotSoldHomepage'
import NewsHomepage from './components/news/NewsHomepage'
import Banner from '@components/Banner/Banner'
import { useEffect, useState } from "react";
import { BANNERS } from '@utility/constants'
import BannerServices from '@services/BannerServices'

function HomePageNew() {
  const [bannerHomepage, setbannerHomepage] = useState<string[]>([])

  useEffect(() => {
    try {
      BannerServices.getBanner(BANNERS.HOMEPAGE)
        .then(data => {
          setbannerHomepage(data?.images)
        })
    } catch (error) {
    
    }
  
  }, []) 
  return (
    <div className='w-full min-h-screen mb-16'>
        <BannerNew className={"block h-[calc(36vh)] lg:h-[calc(100vh-144px)]"} images={bannerHomepage}/>
        {/* <div className="container px-4 lg:px-8 ">

          <Banner className="my-4 !h-[36vh] lg:hidden " images={bannerHomepage} />
        </div> */}
        <UtilHompage />
        <HomepageSale />
        <ProductNewHomepage />
        <ProductHotSoldHomepage />
        <NewsHomepage />
    </div>
  )
}

export default HomePageNew