import { some } from '@utility/helper'
import React from 'react'
import { Link } from 'react-router-dom'

function NewsItemHome({news}: some) {
  return (
    <div className='pb-0 md:pb-8 flex md:flex-col gap-4 lg:gap-0 border rounded-[8px] md:rounded-t-[8px] border-[rgba(0,0,0,0.12)]'>
        <div className="w-[40%] h-full sm:h-[168px] md:h-[175px] md:w-full rounded-s-[8px] md:rounded-t-[8px]">
            <img src={news.imageUrl}  className='w-full h-full object-cover  rounded-s-[8px] md:rounded-t-[8px]' alt="news" />
        </div>
        <div className="flex-1 my-4 px-4">
          <Link to={"/about-us/news/" + news.id} className='line-clamp-3 font-semibold md:font-normal text-normal text-[#131315]'>
            {news.title}  
          </Link>
          <p className='block md:hidden mt-4 line-clamp-3 sm:line-clamp-4 md:line-clamp-5'>
            {news.describe}  
          </p>
        </div>
    </div>
  )
}

export default NewsItemHome