import React, { useEffect, useState } from 'react'
import TitleHomepage from '../title/TitleHomepage'
import { BANNERS, ROUTES } from '@utility/constants'
import SubtitleHomepage from '../title/SubtitleHomepage'
import BannerServices from '@services/BannerServices'
import ProductServices, { ProductItem } from '@services/ProductServices'
import useViewport from '@hooks/useViewPort'
import CollectionProductHomepage from '../collection/CollectionProductHomepage'

function ProductNewHomepage() {
  const { width } = useViewport()
  const [bannerRecentlyHomepage, setbannerRecentlyHomepage] = useState<string[]>([])
  const [newProducts, setNewProducts] = useState<ProductItem[]>([])

  
  useEffect(() => {
    try {

      BannerServices.getBanner(BANNERS.RECENTLY_HOMEPAGE)
        .then(data => {
          setbannerRecentlyHomepage(data?.images)
        })


      ProductServices.getProductNew()
        .then(data => {
          setNewProducts(data.data)
          if(width > 1280) {
            setNewProducts([...data.data.slice(0, 10)])
          } else if(width > 640) {
            setNewProducts([...data.data.slice(0, 7)])
          } else {
            setNewProducts([...data.data.slice(0, 6)])
          }
        })

    } catch (error) {
      
    }
  
  }, [])  

  useEffect(() => {
    sliceProdcutList()
  }, [width])

  const sliceProdcutList = () => {
 
    if(width > 1280) {
      setNewProducts([...newProducts.slice(0, 10)])
    } else if(width > 1023) {
      setNewProducts([...newProducts.slice(0, 7)])
    } else {
      setNewProducts([...newProducts.slice(0, 6)])
    }
  }
  

  return (
    <div className='container px-4 lg:px-16 xl:px-24 mt-8 md:mt-16 '>
      <TitleHomepage text="HÀNG MỚI VỀ"/>
      <SubtitleHomepage text="HÀNG MỚI VỀ" link={ROUTES.search.filter}/>
      <div className="py-5 md:py-8 lg:py-12">
          <CollectionProductHomepage data={newProducts} banner={bannerRecentlyHomepage} />
      </div>
    </div>
  )
}

export default ProductNewHomepage