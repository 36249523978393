import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import './banner.css'
import { useEffect, useState } from "react";
import BannerServices from "@services/BannerServices";
import { BANNERS } from "@utility/constants";

const BannerNew = ({images, className, ...props}: any) => {

 
  const pagination = {
    clickable: true,
    renderBullet: function (index: any, className: any) {
      return '<span class="' + className + '"></span>';
    },
  };

  return (
    <div className={`w-full   ${className}`} {...props}>
      <Swiper 
        className="w-full h-full "
        modules={[Autoplay, Pagination]}
        spaceBetween={24}
        slidesPerView={1}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        loop={true}
        pagination={pagination}
      >
      {
        images?.map((src:string, i:any) => {
          return <SwiperSlide key={i}>
              <img src={src} className="w-full h-full object-cover " alt="image banner homepage" 
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src="/images-raw/noimage.jpg";
                }}
              />
            </SwiperSlide>
        })
      }
      </Swiper>
    </div>
    
  );
};

export default BannerNew;
