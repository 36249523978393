import { AddHomepageSVG } from '@assets/icons'
import { CartItem, useCart } from '@contexts/CartContext'
import { ToastContex } from '@contexts/ToastContex'
import { ProductColor, ProductItem, ProductSize } from '@services/ProductServices'
import { ROUTES } from '@utility/constants'
import { formatMoney } from '@utility/helper'
import clsx from 'clsx'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

type Props = {
  product: ProductItem
}

function ProductCardCollectionHomepage({product}: Props) {
  const navigate = useNavigate()
  const { listToast, onAddToast } = useContext(ToastContex);
  const {onChangeItem} = useCart()

  const [colorSelected, setcolorSelected] = useState<ProductColor | undefined>(product?.colors[0])
  const [sizeSelected, setsizeSelected] = useState<ProductSize | undefined>()

  
  useEffect(() => {
    setSize()
  }, [colorSelected])
  

  const setSize = () => {
    setsizeSelected(undefined)
    if(colorSelected && colorSelected.sizes) {
      for(let i = 0; i < colorSelected.sizes.length; i++) {
        if(colorSelected.sizes[i].total > 0) {
          setsizeSelected(colorSelected.sizes[i])
          break
        }
      }
    }
  }

  
  const handleBuyNow = () => {
    
    if(!colorSelected || !sizeSelected) {
      onAddToast({ type: "error", message: `Số lượng sản phẩm đã hết!` });
      return
    }
    
    const cartItem: CartItem = {
      id: product?.id || "",
      name: product?.name || "",
      image: product.images[0] || colorSelected.image || "",
      price: sizeSelected.priceSale || 0,
      size: sizeSelected,
      color: colorSelected,
      quantity: 1,
      choose: false,
      sku: product?.sku || "",
    }
    onChangeItem(product?.id || "", cartItem)
    onAddToast({ type: "success", message: `Thêm sản phẩm thành công` });
    // navigate("/cart")
  }

  return (
    <div className='pb-4 group product-shadown-homepage'>
      <div className="bg-[#ECECEC] relative">
       <img
          className="w-full aspect-[5/4] object-cover"
          src={(product?.images && product?.images.length > 0 && product?.images[0] ? product?.images[0] :  "cuongnm")}
          alt="category"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src="/images-raw/noimage.jpg";
          }}
          onClick={() => {navigate(ROUTES["product"].detail(product.id))}}
        />
        <div className="hidden group-hover:flex overflow-x-auto scrollbar-hide justify-evenly gap-[1px] items-center group-hover:bottom-0 h-[30px] w-full absolute -bottom-[30px] bg-slate-400 left-0  transition-all duration-700">
          {
            product?.colors[0].sizes.map((s) => {
              return <span className={clsx('px-1 text-white cursor-pointer bg-[#131315] w-full h-full flex justify-center items-center', {
                "bg-[#FF0000] ": sizeSelected?.sizeName == s.sizeName,
                "line-through bg-gray-500": s.total <= 0
              })} key={s.sizeName}
                onClick={() => s.total > 0 && setsizeSelected(s)}
              >{s.sizeName}</span>
            })
          }
          
        </div>
      </div>
      <div className="px-0 md:px-4 mt-4">
        <Link className="" to={ROUTES["product"].detail(product.id)}>
          <h1 className="mb-1 text-[#131315] text-normal line-clamp-2 h-[40px]">
            {product.name}
          </h1>
        </Link>
        <div className="flex  md:py-1 mt-2  items-center">
            <div className={clsx("text-[#f00] text-normal1 font-bold mr-2", {
              "!text-[#131315]": product.saleMax == 0
            })}>{formatMoney(product.price - (product.price * product.saleMax / 100))}</div>
            { product.saleMax != 0 && 
              <div className="flex items-baseline h-full">
                <div className="text-[#9C9C9C] mr-2 text-wap-regular2 line-through">
                  {formatMoney(product.price)}
                </div>
                <div className="relative bg-[#FE0000] w-fit px-2 h-[22px] text-white flex items-center justify-center rounded-[4px]  text-wap-regular1">
                  -{product.saleMax}% 
                  <div className="absolute -left-[3px] top-1/2 -translate-y-[50%] w-[6px] h-[6px] rotate-45 bg-[#FE0000] "></div>
                </div>
              </div>
            }
          </div>
          <div className="mt-2 md:mt-4 cursor-pointer"
            onClick={() => handleBuyNow()}
          >
            <AddHomepageSVG />
          </div>
      </div>
    </div>
  )
}

export default ProductCardCollectionHomepage