import { CartItem, useCart } from '@contexts/CartContext';
import { ToastContex } from '@contexts/ToastContex';
import { ProductItem } from '@services/ProductServices';
import { ROUTES } from '@utility/constants';
import { formatMoney } from '@utility/helper';
import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom';
interface Props {
  data: ProductItem;
  color: string
}

const SaleProductCard = (props: Props) => {
  const navigate = useNavigate()
  const { data, color } = props;
  const {onChangeItem} = useCart()
  const { listToast, onAddToast } = useContext(ToastContex);
  // console.log(data);

  const handleBuyNow = () => {
    let size, color;
    for(let i = 0; i < data.colors.length; i++) {
      for(let j = 0; j < data.colors[i].sizes.length; j++) {
        if( data.colors[i].sizes[j].total > 0) {
          size = data.colors[i].sizes[j]
          color = data.colors[i]
          break
        }
      }
    }
    
    if(!size || !color) {
      onAddToast({ type: "error", message: `Số lượng sản phẩm đã hết!` });
      return
    }
    
    const cartItem: CartItem = {
      id: data?.id || "",
      name: data?.name || "",
      image: color.image || data.images[0] || "",
      price: size.priceSale || 0,
      size: size,
      color: color,
      quantity: 1,
      choose: true,
      sku: data?.sku || "",
    }
    onChangeItem(data?.id || "", cartItem)
    navigate("/cart")
  }
  
  return (
    <div className="rounded-sm relative h-full hover:cursor-pointer"
    >
      <div className="rounded-sm flex flex-col items-center bg-white ">
        
        <img
          className="w-full rounded-t-sm aspect-[5/4] object-cover"
          src={(data?.images && data?.images[0])}
          alt="category"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src="/images-raw/noimage.jpg";
          }}
          onClick={() => {navigate(ROUTES["product"].detail(data.id))}}
        />
        <div className="w-full rounded-b-sm  text-white py-4 md:py-6 px-4 md:px-6"
          style={{
            background: "url(./images-raw/homepage-new/sale-background.png)",
            backgroundColor: color,
          }}
        >
          <div className="bg-[#FE0000] w-[64px] h-[24px] flex items-center justify-center rounded-[4px]  text-wap-regular1">
            -{data.saleMax}% 
          </div>
          <div className={`flex-1 w-full flex flex-col mt-4`}>
            <Link className="" to={ROUTES["product"].detail(data.id)}>
              <h1 className="mb-1 text-white text-normal1 lg:text-normal2 font-semibold line-clamp-2 h-[44px] lg:h-[60px]">
                {data.name}
              </h1>
            </Link>
            <div className="flex  py-1 lg:mt-2 flex-col items-baseline">
              <div className="text-background-100 text-wap-regular2 lg:text-normal line-through h-[14px]">
                {formatMoney(data.price)}
              </div>
              <div className="text-white text-title font-bold mt-1 lg:mt-2">{formatMoney(data.price - data.price * data.saleMax / 100)}</div>
            </div>
          </div>
          <div className="w-full flex justify-between items-baseline mt-3">
            <button className="btn rounded-[8px] px-8 py-3 h-fit bg-white text-black text-wap-regular2 lg:text-normal font-semibold"
              onClick={handleBuyNow}
            >
              Mua ngay
            </button>
          </div>
        </div>
      </div>
      {/* {
        data?.colors[0]?.sizes[0]?.sale > 0 && 
          <div className="absolute top-0 left-0">
            <HotDiscountFlag value={data.colors[0].sizes[0].sale} />
          </div>
      } */}
    </div>
  );
};


export default SaleProductCard