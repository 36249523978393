import { SearchIcon } from '@assets/icons';
import useFocusOut from '@hooks/useFocusOut';
import useI18n from '@hooks/useI18n';
import SearchDropdown from '@layout/components/SearchDropDown';
import { ROUTES } from '@utility/constants';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';

function HeaderSearchExpand() {
  const {t} = useI18n()
  const { clickShow, setClickShow, ref: searchRef } = useFocusOut();
  const [keyword, setkeyword] = useState("")
  return (
    <div className="relative py-2 w-[calc(100%-32px)] lg:w-1/2" ref={searchRef}>
      {clickShow && keyword && keyword.length > 0 && <SearchDropdown keyword={keyword} close={() => setClickShow(false)} />}
      <div className={`relative`}>
        <input
          value={keyword} 
          onChange={(e:any) => setkeyword(e.target.value)}
          className={`w-full h-8 rounded-full outline-none border-main focus:border text-wap-regular2 pl-5 pr-20`}
          placeholder={t("header.search") || "Tìm kiếm tại đây"}
          onFocus={() => setClickShow(true)}
        />
        <Link to={`${ROUTES.search.index}?keyword=${keyword}`} className="flex justify-center items-center top-0 h-full absolute right-0 px-5 rounded-full bg-main">
          <SearchIcon className="stroke-white" />
        </Link>
      </div>
    </div>
  )
}

export default HeaderSearchExpand