import React, { useEffect, useState } from 'react'
import TitleHomepage from '../title/TitleHomepage'
import SubtitleHomepage from '../title/SubtitleHomepage'
import { BANNERS, ROUTES } from '@utility/constants'
import useViewport from '@hooks/useViewPort'
import ProductServices, { ProductItem } from '@services/ProductServices'
import BannerServices from '@services/BannerServices'
import CollectionProductHomepage from '../collection/CollectionProductHomepage'

function ProductHotSoldHomepage() {
  const { width } = useViewport()
  const [bannerHotsoldHomepage, setbannerHotsoldHomepage] = useState<string[]>([])
  const [hotSold, setHotSold] = useState<ProductItem[]>([])


  useEffect(() => {
    try {
      BannerServices.getBanner(BANNERS.HOTSOLD_HOMEPAGE)
      .then(data => {
        setbannerHotsoldHomepage(data?.images)
      })

      ProductServices.getHotSold()
        .then(data => {
          setHotSold(data.data)
          if(width > 1280) {
            setHotSold([...data.data.slice(0, 10)])
          }else if(width > 640) {
            setHotSold([...data.data.slice(0, 7)])
          } else {
            setHotSold([...data.data.slice(0, 6)])
          }
        })


    } catch (error) {
      
    }
  
  }, [])  
  
  useEffect(() => {
    sliceProdcutList()
  }, [width])

  const sliceProdcutList = () => {
 
    if(width > 1280) {
      setHotSold([...hotSold.slice(0, 10)])
    } else if(width > 1023) {
      setHotSold([...hotSold.slice(0, 7)])
    } else {
      setHotSold([...hotSold.slice(0, 6)])
    }
  }

  return (
    <div className='container px-4 lg:px-16 xl:px-24 mt-8 md:mt-16 '>
      <TitleHomepage text="SẢN PHẨM BÁN CHẠY"/>
      <SubtitleHomepage text="SẢN PHẨM BÁN CHẠY" link={ROUTES.search.filter}/>
      <div className="py-5 md:py-8 lg:py-12">
        <CollectionProductHomepage data={hotSold} banner={bannerHotsoldHomepage} />
      </div>
    </div>
  )
}

export default ProductHotSoldHomepage