import React, { useEffect, useState } from 'react'
import TitleHomepage from '../title/TitleHomepage'
import SubtitleHomepage from '../title/SubtitleHomepage'
import { ROUTES } from '@utility/constants'
import NewsServices from '@services/NewsServices'
import { some } from '@utility/helper'
import NewsItemHome from './NewsItemHome'

function NewsHomepage() {

  const [newsData, setNewsData] = useState<some>([])


  useEffect(() => {
    try {

      NewsServices.get({page: 0, limit: 4})
        .then(data => {
          setNewsData(data.data.data)
          
        })
    } catch (error) {
      
    }
  
  }, [])  

  return (
    <div className='container px-4 lg:px-16 xl:px-24 mt-8 md:mt-16 '>
      <TitleHomepage text="TIN TỨC"/>
      <SubtitleHomepage text="TIN TỨC" link={ROUTES.intro.index + "/" + ROUTES.intro.news.index}/>
      <div className="py-5 md:py-8 lg:py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4  gap-4 ">
          {
            newsData && newsData.length > 0 && newsData.map((n:any, i:any) => {
              return <NewsItemHome news={n} key={i} />
            })
          }
        </div>
      </div>
    </div>
  )
}

export default NewsHomepage