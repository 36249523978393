import { ViewmoreHomepageArrowSVG } from '@assets/icons';
import CategoryProductServices from '@services/CategoryProductServices';
import { ProductItem } from '@services/ProductServices';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SaleProductCard from './SaleProductCard';
import clsx from 'clsx';
import useViewport from '@hooks/useViewPort';

const fake: ProductItem[] = [
  {
    id: "1",
    images: ["https://cdn-images.kiotviet.vn/newsport700/c3357b56363a4429ac158e82d4646008.jpg"],
    video: "",
    price: 1339000,
    sku: "977219110009Den-39",
    name: "Giày chạy - 977219110009 - đen/xám gỗ",
    detail: "string",
    policy: "string",
    category: {
      categoryId: 1304079,
      categoryName: "XTEP 23Q2",
      categorySId: 8251,
      createdAt: 1689608749965,
      hasChild: false,
      parentId: 1,
      parentSId: 1
    },
    trademark: {
      id: "1",
      image: "",
      name: "XTEP",
      menuShow: true,
      male: null,
      female: null
    },
    colors: [],
    imageCheck: "",
    seen: 11,
    sold: 11,
    priceMin: 1339000,
    priceMax: 1339000,
    saleMin: 10,
    saleMax: 10,
    createdAt: 1,
    updatedAt: 1
  },
  {
    id: "1",
    images: ["https://cdn-images.kiotviet.vn/newsport700/c3357b56363a4429ac158e82d4646008.jpg"],
    video: "",
    price: 1339000,
    sku: "977219110009Den-39",
    name: "Giày chạy - 977219110009 - đen/xám gỗ",
    detail: "string",
    policy: "string",
    category: {
      categoryId: 1304079,
      categoryName: "XTEP 23Q2",
      categorySId: 8251,
      createdAt: 1689608749965,
      hasChild: false,
      parentId: 1,
      parentSId: 1
    },
    trademark: {
      id: "1",
      image: "",
      name: "XTEP",
      menuShow: true,
      male: null,
      female: null
    },
    colors: [],
    imageCheck: "",
    seen: 11,
    sold: 11,
    priceMin: 1339000,
    priceMax: 1339000,
    saleMin: 10,
    saleMax: 10,
    createdAt: 1,
    updatedAt: 1
  },
  {
    id: "1",
    images: ["https://cdn-images.kiotviet.vn/newsport700/c3357b56363a4429ac158e82d4646008.jpg"],
    video: "",
    price: 1339000,
    sku: "977219110009Den-39",
    name: "Giày chạy - 977219110009 - đen/xám gỗ",
    detail: "string",
    policy: "string",
    category: {
      categoryId: 1304079,
      categoryName: "XTEP 23Q2",
      categorySId: 8251,
      createdAt: 1689608749965,
      hasChild: false,
      parentId: 1,
      parentSId: 1
    },
    trademark: {
      id: "1",
      image: "",
      name: "XTEP",
      menuShow: true,
      male: null,
      female: null
    },
    colors: [],
    imageCheck: "",
    seen: 11,
    sold: 11,
    priceMin: 1339000,
    priceMax: 1339000,
    saleMin: 10,
    saleMax: 10,
    createdAt: 1,
    updatedAt: 1
  },
  {
    id: "1",
    images: ["https://cdn-images.kiotviet.vn/newsport700/c3357b56363a4429ac158e82d4646008.jpg"],
    video: "",
    price: 1339000,
    sku: "977219110009Den-39",
    name: "Giày chạy - 977219110009 - đen/xám gỗ",
    detail: "string",
    policy: "string",
    category: {
      categoryId: 1304079,
      categoryName: "XTEP 23Q2",
      categorySId: 8251,
      createdAt: 1689608749965,
      hasChild: false,
      parentId: 1,
      parentSId: 1
    },
    trademark: {
      id: "1",
      image: "",
      name: "XTEP",
      menuShow: true,
      male: null,
      female: null
    },
    colors: [],
    imageCheck: "",
    seen: 11,
    sold: 11,
    priceMin: 1339000,
    priceMax: 1339000,
    saleMin: 10,
    saleMax: 10,
    createdAt: 1,
    updatedAt: 1
  }
]


const colorFake = ["#322D35", "#213D7F", "#335851"]

type Props = {
  hotSold: ProductItem[]
}

function SaleProductHomepage({hotSold}: Props) {

  
  const {width} = useViewport()
  const slideRef = useRef<any>(null);
  const [index, setIndex] = useState(0)
  
  const nextSlide = useCallback(() => {
    if (slideRef.current) {
      slideRef.current.swiper.slideNext();
      setIndex(slideRef?.current?.swiper.realIndex)
    }
  }, []);
  const prevSlide = useCallback(() => {
    if (slideRef.current) {
      slideRef.current.swiper.slidePrev();
      setIndex(slideRef?.current?.swiper.realIndex)
    }
  }, []);

  const onActiveIndexChange = (swiper: any) => {
    setIndex(swiper.realIndex);
  };
  return (
    hotSold && hotSold.length > 0 ? 
    <div className="">
      <div className="">
        <div className="relative">
          {
             hotSold.length - (width >= 1536 ? 4 : 3) > 0 && <>
              <button className="absolute hidden lg:block lg:-left-16   top-1/2 -translate-y-2/4" onClick={prevSlide}>
                <ViewmoreHomepageArrowSVG className={clsx("fill-[#9C9C9C] h-10 w-10 rotate-180", {
                  "!fill-[#FF0000]": index > 0
                })} />
              </button>
              <button className="absolute hidden lg:block lg:-right-16 top-1/2 -translate-y-2/4" onClick={nextSlide}>
                <ViewmoreHomepageArrowSVG className={clsx("fill-[#9C9C9C] h-10 w-10", {
                  "!fill-[#FF0000]": index < hotSold.length - (width >= 1536 ? 4 : 3)
                })} />
              </button>
             </>
          }
         
          <Swiper
            onActiveIndexChange={onActiveIndexChange}
            className=""
            modules={[Autoplay, Navigation]}
            ref={slideRef}
            spaceBetween={18}
            slidesPerView={1}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              596: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
              1536: {
                slidesPerView: 4,
              },
            }}
          >
            {hotSold.map((it, idx) => (
              <SwiperSlide className="p-2 h-full" key={idx}>
                <SaleProductCard  data={it} color={colorFake[idx % 3]}/>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div> : <></>
  );
}

export default SaleProductHomepage