import { ViewmoreHomepageArrowSVG } from '@assets/icons'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function SubtitleHomepage({text, link}: any) {
  const navigate = useNavigate()
  return (
    <div className='flex justify-between items-center '>
        <h3 className='text-[32px] leading-[36px] md:text-[40px] md:leading-[46.88px] font-bold uppercase'>{text}</h3>
        {link && 
        <div className="flex items-center justify-center hover:cursor-pointer"
          onClick={() => navigate(link)}
        >
          <span className='mr-4 whitespace-nowrap'>Xem tất cả</span>
          <ViewmoreHomepageArrowSVG  className='flex-shrink-0'/>
        </div>
        }
    </div>
  )
}

export default SubtitleHomepage